import { Link, To } from "react-router-dom";
import { publications } from "../../assets/examples";
import { PublicationType, SADElementType } from "../../global/types";
import { useEffect, useState } from "react";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { db } from "../../App";

type BlogCardPropsType = {
    article:SADElementType;
    onClick?: () => void;
    linkTo?:To;
};


export const BlogCard:React.FC<BlogCardPropsType> = ({article, onClick, linkTo}) => {
    // A particular property for these cards is that they are aligned to the grid, with the
    // default aspect ratio specified (16/12 = 4/3) then, for the cards having the less content
    // in that card, the image is stretched to fill the rest available space in that card.
    
    const CardContent = () => {
        return (
            <button
                className="group flex  w-full flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800"
                onClick={onClick}
            >
                <div className={"overflow-hidden aspect-[16/12] w-full"}>
                    <img
                        className="border h-full w-full object-fill rounded-t-xl"
                        src={article.bannerImgUrl||"http://flag-cmr.org/pictures/actus/logo_flag.png"}
                        alt="Image Description"
                    />
                </div>
                <div className="p-2 md:p-4">
                    <h3 className="text-sm lg:text-base xl:text-lg font-medium text-gray-800 group-hover:text-blue-600 dark:text-gray-300 dark:group-hover:text-white">
                        {article.title}
                    </h3>
                    <p className="text-xs uppercase text-gray-600 dark:text-gray-400">
                        {article.subtitle}
                    </p>
                    {/* <p className="text-sm mt-2">
                        {article.description}
                    </p> */}
                </div>
            </button>
        )
    }
    if(linkTo) {
        return (
            <Link
            to={linkTo}
        >
            <CardContent/>
        </Link>
        )
    } else return (
        <CardContent/>
    )
    
};

const Blog = () => {
    const [blogArticles, setBlogArticles] = useState<SADElementType[]>([]);
    
    const getAndSetSADArticles = async () => {
        const q = query(collection(db, 'sad'), orderBy('index', 'desc'), limit(3))
        const dasDocumentsSnapshot = await getDocs(q);
        const docsData = dasDocumentsSnapshot.docs.map(doc => ({...doc.data(), id:doc.id}) as unknown as SADElementType);
        setBlogArticles(docsData);
    };

    useEffect(() => {
        getAndSetSADArticles();
    }, []);
    return (
        <div className="w-full bg-black bg-opacity-50 px-4">
            <div className="max-w-[85rem] xl:h-4/5 py-4 sm:px-6 lg:px-8 mx-auto">
                <div className="max-w-2xl text-center mx-auto mb-4 lg:mb-8">
                    <h2 className="text-md font-bold md:text-xl md:leading-tight text-white">
                        NOTRE ACTUALITÉ
                    </h2>
                </div>
                <div className="grid md:grid-cols-3 gap-6 mb-10 lg:mb-14 2xl:grid-cols-4">
                    {
                        blogArticles.slice(0, 3).map(article => (
                            <BlogCard
                                linkTo={'/das/'+article.DAS+'?id='+article.id}
                                article={article}
                            />
                        ))
                    }
                    
                </div>
                {/* <div className="text-center">
                    <div className="inline-block bg-white border shadow-sm rounded-full dark:bg-slate-900 dark:border-gray-800">
                        <div className="py-3 px-4 flex items-center gap-x-2">
                            <p className="text-gray-600 dark:text-gray-400">
                                Want to read more?
                            </p>
                            <a className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium" href="../docs/index.html">
                                Go here
                                <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
};

export default Blog;
type Partner = {
    id:string
    name:string
    logo:string
}

const partners:Partner[] = [
    {
        id:'',
        name:'',
        logo:require('./assets/partners/eu.jpg')
    },
    {
        id:'',
        name:'',
        logo:require('./assets/partners/usa-flag.jpg'),
    },
    {
        id:'',
        name:'',
        logo:require('./assets/partners/world-resources-institute.jpg'),
    },
    {
        id:'',
        name:'',
        logo:require('./assets/partners/nicfi.jpg'),
    },
    {
        id:'',
        name:'',
        logo:require('./assets/partners/cidt_uw.jpg')
    },
    {
        id:'',
        name:'',
        logo:require('./assets/partners/pfbc.png')
    },
    {
        id:'',
        name:'',
        logo:require('./assets/partners/eie.png')
    },
    {
        id:'',
        name:'',
        logo:require('./assets/partners/rem.jpg')
    },
    {
        id:'',
        name:'',
        logo:require('./assets/partners/conservation-justice.jpg'),
    },
    {
        id:'',
        name:'',
        logo:require('./assets/partners/wg.png')
    },
    {
        id:'',
        name:'',
        logo:require('./assets/partners/ogf.png')
    },
    {
        id:'',
        name:'',
        logo:require('./assets/partners/cagdf.png')
    },    {
        id:'',
        name:'',
        logo:require('./assets/partners/ciedd.jpg')
    },
    {
        id:'',
        name:'',
        logo:require('./assets/partners/ced.png')
    },
    {
        id:'',
        name:'',
        logo:require('./assets/partners/foder.png')
    },
    // {
    //     id:'',
    //     name:'',
    //     logo:require('./assets/partners/brainforest.jpg')
    // },
    // {
    //     id:'',
    //     name:'',
    //     logo:require('./assets/partners/wcf.jpg')
    // },
    // {
    //     id:'',
    //     name:'',
    //     logo:require('./assets/partners/wri.png')
    // },
    // {
    //     id:'',
    //     name:'',
    //     logo:require('./assets/partners/foodandagric_org.jpg')
    // },
    // {
    //     id:'',
    //     name:'',
    //     logo:require('./assets/partners/sweden_gov.png')
    // },
    
];

const PartnerCard:React.FC<Partner> = ({logo}) => {
    return (
        <div className="p-2 md:p-4 bg-gray-100 rounded-lg dark:bg-slate-800 flex items-center">
           <img src={logo} alt="" />
        </div>

    )
}

const Partners = () => {
    return (
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="sm:w-1/2 xl:w-1/3 mx-auto text-center mb-6 md:mb-12">
                <div className="py-[0.625rem] px-1 rounded-full bg-white">
                    <h2 className="text-md md:text-xl font-bold  text-gray-800 dark:text-gray-200">
                        NOS PARTENAIRES
                    </h2>
                </div>
            </div>
            <div className="grid grid-cols-4 sm:grid-cols-6 md:grid-cols-9 gap-3 lg:gap-3 lg:mx-8  grid-auto-rows min-content">
                {
                    partners.map((item, index) => <PartnerCard key={index} {...item}/>)
                }
                
            </div>
        </div>
    )
};

export default Partners;